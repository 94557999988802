<template>
    <li v-if="dataRender && dataRender.length > 0" class="nav__item group hover:bg-blacks-100">
        <div class="flex items-center justify-between gap-0.5 text-blacks-100 duration-200 cursor-pointer font-medium">
            <p @click="getItem($event.target)" class="flex-1 py-3 group-hover:text-white">{{ title }}</p>
            <span
                @click="getItem($event.target)"
                class="item-close i-ic:twotone-keyboard-arrow-down transform -rotate-90 text-xl group-hover:text-white"
            ></span>
        </div>
        <ul v-if="dataRender && dataRender.length > 0" class="nav__sub">
            <MenuBackItem />

            <li v-for="(item, index) in dataRender" :key="index" class="nav__item hover:bg-blacks-100 hover:text-white">
                <nuxt-link :to="item?.url" class="nav__link py-3 font-medium">{{
                    isConvert ? convert(item?.title) : item?.title
                }}</nuxt-link>
            </li>
        </ul>
    </li>
</template>

<script setup lang="ts">
import { convert } from 'html-to-text'
defineProps({
    dataRender: {
        type: Array as any
    },
    title: {
        type: String as any
    },
    isConvert: {
        type: Boolean as any,
        default: false
    }
})
const getItem = (event) => {
    event.parentNode.nextElementSibling?.classList.add('is-active')
}
</script>

<style></style>
